<template>
  <v-app
    :class="{
      'landing-v1': !$featureFlag('feature_show_new_landing')
    }"
    full-height
  >
    <component :is="$featureFlag('feature_show_new_landing') ? AppHeaderV2 : AppHeader" />
    <v-main
      v-if="$featureFlag('feature_show_new_landing')"
      class="ssr-fix-main"
    >
      <slot />
      <AppFooter />
    </v-main>
    <BaseMain
      v-else
      :preparatory-padding="APP_HEADER_HEIGHT"
    >
      <slot />
      <AppFooter />
    </BaseMain>
    <AppFixedFooter v-if="!$featureFlag('feature_show_new_landing')" />
  </v-app>
</template>

<script lang="ts" setup>
import { APP_HEADER_HEIGHT, ASYNC_DATA_KEYS } from '~/constants'
import { homeApi } from '~/api'
import { partnerSectionApi } from '~/components/PartnerSection/api'
import {
  AppHeader,
  AppHeaderV2
} from '#components'

const [{ data }] = await Promise.all([
  useAsyncData(ASYNC_DATA_KEYS.statics, () => homeApi.getStatistics()),
  useAsyncData(ASYNC_DATA_KEYS.logos, () => partnerSectionApi.getLogos())
])

if (!data.value) {
  throw createError({
    statusCode: 500
  })
}
</script>
