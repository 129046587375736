import type { ContactFormRequest } from '~/enums'

type SaveContactPayload = {
  requestType: ContactFormRequest
  mobilePhone: string
  town: string
  clinic: string
}

export type Integration = {
  title: string
  iconPath: string
  id: number
  description: string
  tariff: string
  /**
   * @deprecated
   * Удалить при удалении флага feature_show_new_landing
   * */
  logoUrl: string
  showInstruction: boolean
  order: null
}

export type IntegrationGroup = {
  groupOrder: number
  groupTitle: string
  integrations: Integration[]
}

export type StatisticsDto = {
  lpusCount: number
  directAppointmentsCount: number
  integrationsCount: number
  integrations: Integration[]
  directAppointmentsCountPerMonth: number
  insuranceDirectAppointmentsCount: number
  insuranceDirectAppointmentsCountPerMonth: number
  integrationGroups: IntegrationGroup[]
  lpusCountPerMonth: number
  misesCoint: number
  priceListCount: number
  priceListCountPerMonth: number
  remdDocumentCount: number
  remdDocumentCountPerMonth: number
}

export type SaveContactErrorDto = {
  requestType?: string[]
  mobilePhone?: string[]
  detail?: string
}

export const homeApi = {
  getStatistics() {
    return $apiFetch<StatisticsDto>('/common/')
  },
  saveContact(payload: SaveContactPayload) {
    return $apiFetch<SaveContactErrorDto>('/save_contact/', {
      body: payload,
      method: 'POST'
    })
  }
}
