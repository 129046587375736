import { defineStore } from 'pinia'

export const _useHashNavigation = defineStore('navigationInfo', () => {
  const hashNavigationPromise = ref<Promise<void> | null>(null)
  const isIntersectionNavigation = computed(() => !!hashNavigationPromise.value)
  const router = useRouter()
  const hash = computed(() => router.currentRoute.value.hash.replace('#', ''))

  async function addHash(value: string) {
    if (value === hash.value) {
      return
    }

    if (hashNavigationPromise.value) {
      await hashNavigationPromise.value
    }

    hashNavigationPromise.value = navigateTo({
      hash: value ? `#${value}` : ''
    }, { replace: true }) as Promise<void>

    await hashNavigationPromise.value

    hashNavigationPromise.value = null
  }

  function removeHash() {
    return addHash('')
  }

  return {
    hashNavigationPromise,
    isIntersectionNavigation,
    hash,
    addHash,
    removeHash
  }
})
