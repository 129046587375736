<template>
  <v-dialog
    v-if="$device.isDesktop"
    v-model="isShowFinalScreens"
    width="304"
  >
    <v-card
      v-if="isShowSuccessScreen"
      title="Заявка отправлена"
      class="pt-2"
    >
      <v-card-text class="text-ui-kit-text-secondary">
        Специалист позвонит вам
        в течение одного рабочего дня
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text="Хорошо"
          color="primary"
          @click="hideFinalScreens"
        />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <div class="text-h6 pa-6">
        Отправить новую заявку можно через сутки
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text="Понятно"
          color="primary"
          @click="hideFinalScreens"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-bottom-sheet
    v-else
    v-model="isShowFinalScreens"
  >
    <v-card v-if="isShowSuccessScreen">
      <div class="py-6 px-8">
        <div class="text-center text-h6 mb-2">
          Заявка отправлена
        </div>
        <div class="text-ui-kit-text-secondary text-center">
          Специалист позвонит вам
          в течение одного рабочего дня
        </div>
      </div>
      <v-card-actions>
        <v-btn
          text="Хорошо"
          min-width="100%"
          variant="flat"
          @click="hideFinalScreens"
        />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <div class="text-h6 py-9 px-8 text-center">
        Отправить новую заявку можно через сутки
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text="Понятно"
          min-width="100%"
          variant="flat"
          @click="hideFinalScreens"
        />
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script setup lang="ts">
type Props = {
  modelValue: boolean
  isShowErrorScreen: boolean
  isShowSuccessScreen: boolean
}

const props = defineProps<Props>()
const isShowFinalScreens = useVModel(props)

const hideFinalScreens = () => {
  isShowFinalScreens.value = false
}
</script>
