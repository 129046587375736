import { useWindowScroll, useWindowSize } from '@vueuse/core'
import { isString, sortBy } from 'lodash-es'

type Item = { id: string, el: HTMLElement }

export default defineNuxtPlugin((nuxt) => {
  if (import.meta.server) {
    nuxt.vueApp.directive('hash-intersect', {})

    return
  }

  const items = ref<Array<Item>>([])
  const sortedItems = computed(() => {
    return sortBy(items.value, ({ el }) => el.offsetTop)
  })

  const { y } = useWindowScroll()
  const { height } = useWindowSize()
  const hasIntersect = _useHashNavigation()
  const { addHash, removeHash } = hasIntersect
  /**
   * Возвращает true, если верхняя граница элемента находится выше чем текущий скролл + половина экрана
   * и нижняя граница элемента ниже чем текущий скролл
   */
  const isElemetInBounds = (el: HTMLElement, currentScroll: number) => {
    const hashVisibilityBound = currentScroll + (height.value / 2) // текущий скролл + половина экрана

    const { offsetTop, offsetHeight } = el

    return hashVisibilityBound >= offsetTop && currentScroll <= offsetTop + offsetHeight
  }

  watch(y, async (value) => {
    if (hasIntersect.hashNavigationPromise) {
      await hasIntersect.hashNavigationPromise
    }

    const itemsInArea = sortedItems.value.filter(({ el }) => {
      return isElemetInBounds(el, value)
    })

    if (!itemsInArea.length) {
      removeHash()

      return
    }

    const firstItemBeforeScrollTop = itemsInArea.find(({ el }) => {
      return el.offsetTop >= value
    })

    const targetElement = firstItemBeforeScrollTop ?? itemsInArea[itemsInArea.length - 1]

    addHash(targetElement.id)
  })

  nuxt.vueApp.directive('hash-intersect', {
    mounted: (el, { value }) => {
      const id = value || el.id

      if (!isString(id)) {
        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.error('v-hash-intersect: не передано значение, либо у элемента отсутствует id', el)
        }

        return
      }

      const existingItem = items.value.find(({ id: itemId }) => itemId === id)

      if (existingItem) {
        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.error('v-hash-intersect: на странице уже есть другой элемент с таким же id\n',
            '- целевой элемент: ', el,
            '- элемент, у которого уже есть такой id: ', existingItem.el)
        }

        return
      }

      items.value.push({ id, el })

      el.dataset.intersetId = id

      if (!el.id) {
        el.id = id
      }
    },
    unmounted: (el) => {
      const index = items.value.findIndex(item => item.id === el.dataset.intersetId)

      if (index === -1) {
        return
      }

      items.value.splice(index, 1)
    }
  })
})
