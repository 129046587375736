<template>
  <HomeSection :title="title">
    <div class="d-flex flex-wrap justify-center gc-4 gc-md-6">
      <v-img
        v-for="clinic in clinics"
        :key="clinic.name"
        class="flex-grow-0 clinic-section-img"
        :src="`${clinic.image}.png`"
        :srcset="`${clinic.image}.png 1x, ${clinic.image}_3x.png 3x`"
        :alt="clinic.name"
      />
    </div>
    <v-btn
      class="mt-6 d-md-none"
      block
      @click="$emit('click:open-form')"
    >
      Подключить МедФлекс
    </v-btn>
  </HomeSection>
</template>

<script lang="ts">
import { clinicDeclension, connectedClinicDeclension } from '~/constants'

export default defineComponent({
  name: 'ClinicsSection',
  props: {
    clinicsCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['click:open-form'],
  setup(props) {
    const clinics = [
      {
        name: 'Invitro',
        image: 'images/features/clinics/invitro'
      },
      {
        name: 'Гемотест',
        image: 'images/features/clinics/gemotest'
      },
      {
        name: 'Helix',
        image: 'images/features/clinics/helix'
      },
      {
        name: 'Мать и дитя',
        image: 'images/features/clinics/mother_and_kid'
      },
      {
        name: 'Альфа центр здоровья',
        image: 'images/features/clinics/alpha_health_center'
      },
      {
        name: 'МРТ Эксперт',
        image: 'images/features/clinics/mri_expert'
      },
      {
        name: 'Клиника Фомина',
        image: 'images/features/clinics/fomin_clinic'
      },
      {
        name: 'Клиника Екатерининская',
        image: 'images/features/clinics/ekaterininsky_clinic'
      }
    ]

    const title = computed(() =>
      `К МедФлексу ${pluralize(props.clinicsCount, connectedClinicDeclension, false)} ${pluralize(props.clinicsCount, clinicDeclension)}`)

    return {
      clinics,
      title
    }
  }
})
</script>

<style lang="scss">
.clinic-section-img {
  width: 156px;
  height: 69px;

  @media (min-width: $desktop-breakpoint) {
    width: 264px;
    height: 109px;
  }
}
</style>
