<template>
  <v-container class="contact-form-layout landing-container">
    <div
      class="pt-6 pb-4 px-4 bg-ui-kit-bg-primary contact-form-layout-container
            d-md-flex justify-md-space-between pa-md-12"
    >
      <div
        style="margin-right: 125px"
        class="w-100"
      >
        <h3
          class="text-h5 text-center mb-2 text-md-h3 mb-md-6 text-md-start"
          v-text="title"
        />
        <p
          class="text-ui-kit-text-secondary text-center mb-6 text-md-start mb-md-0"
          v-text="subtitle"
        />
      </div>
      <div class="contact-form-layout-container__form bg-ui-kit-bg-gray-0 w-100 py-6 px-4 pt-md-8 pb-md-6 px-md-6">
        <v-form
          v-model="isValidForm"
          validate-on="submit"
          @submit.prevent="handleClickSend"
        >
          <PhoneInput
            v-model="phoneNumber"
            class="mb-4"
            label="Номер телефона"
            placeholder="+7 9XX XXX XX XX"
            density="comfortable"
            validate-on="blur"
            :rules="[
              (value: string) => value.length === FORMATED_PHONE_LENGTH || 'Укажите номер в формате +7 9XX XXX XX XX'
            ]"
            persistent-placeholder
            @click:control="textField?.resetValidation()"
          />

          <v-btn
            min-width="100%"
            type="submit"
            text="Заказать консультацию"
            class="mb-6"
            :loading="formLoading"
          />

          <div class="text-center text-body-2">
            Нажимая кнопку, вы&nbsp;даёте согласие на&nbsp;<a
              target="_blank"
              class="text-primary text-decoration-none"
              :href="privacyPolicyLink"
            >обработку персональных&nbsp;данных</a>
          </div>
        </v-form>
      </div>
    </div>
    <ContactFormFinalScreens
      v-model="isShowFinalScreens"
      :is-show-success-screen="isShowSuccessScreen"
      :is-show-error-screen="isShowErrorScreen"
    />
  </v-container>
</template>

<script setup lang="ts">
import { useContactForm } from '~/components/ContactForm/composables/useContactForm'
import { FORMATED_PHONE_LENGTH } from '~/components/ContactForm/constants'

type Props = {
  title?: string
  subtitle?: string
}

withDefaults(defineProps<Props>(), {
  title: 'Подключитесь к\u00A0МедФлексу',
  subtitle: 'Наш менеджер свяжется с вами и расскажет подробности'
})

const {
  phoneNumber,
  textField,
  isValidForm,
  handleClickSend,
  formLoading,
  privacyPolicyLink,
  isShowSuccessScreen,
  isShowErrorScreen,
  isShowFinalScreens
} = useContactForm()
</script>

<style scoped lang="scss">
@use "sass:map";

.contact-form-layout {
  margin-bottom: 60px;

  &-container {
    border-radius: 16px;

    &__form {
      border-radius: 8px;
    }
  }

  @media #{map.get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 100px;
  }
}
</style>
