<template>
  <div>
    <slot :click="() => isShowDialog = true">
      <v-btn
        class="d-none d-md-block"
        height="60"
        rounded="lg"
        :width="mdWidth"
        @click="isShowDialog = true"
      >
        <span
          class="text-h6"
          v-text="text"
        />
      </v-btn>

      <v-btn
        class="d-md-none"
        :text="text"
        @click="isShowDialog = true"
      />
    </slot>

    <client-only>
      <ContactFormDialogV2
        v-if="$vuetify.display.mdAndUp"
        v-model="isShowDialog"
      />
      <ContactFormBottomSheet
        v-else
        v-model="isShowDialog"
      />
    </client-only>
  </div>
</template>

<script setup lang="ts">
type Props = {
  text?: string
  mdWidth?: string | number
}

withDefaults(defineProps<Props>(), {
  text: 'Подключиться к МедФлекс',
  mdWidth: 'auto'
})

const isShowDialog = ref(false)
</script>

