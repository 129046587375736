<template>
  <HomeSection
    class="container-indent landing-container"
    title="Наши партнёры"
    :subtitle="subtitle"
  >
    <template v-if="!$featureFlag('feature_show_new_landing')">
      <div class="d-flex flex-wrap justify-center gc-4 gc-md-3">
        <v-img
          v-for="integration in statistics.integrations"
          :key="integration.title"
          class="flex-grow-0 partner-section-img"
          :src="integration.logoUrl"
          :alt="integration.title"
        />
      </div>
      <BorderedSheet
        title="Интегрируемся с другими сервисами"
        text="С помощью API МедФлекс. Нужно только передать сервису ключ доступа (токен)."
        btn-text="API для интеграции"
        btn-href="https://api.medflex.ru/"
      />
    </template>
    <template v-else>
      <div
        v-if="!showOnlyPartnerType"
        class="d-flex justify-center gc-2 mb-6"
      >
        <v-chip-group v-model="selectedFilterId">
          <v-chip
            v-for="chip in PARTNERS_CHIPS"
            :key="chip.value"
            selected-class="bg-ui-kit-bg-primary border-color-primary"
          >
            {{ chip.title }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="d-flex flex-wrap justify-center gc-4 gc-md-3">
        <v-img
          v-for="selectedImage in selectedImages"
          :key="selectedImage.title"
          class="flex-grow-0 partner-section-img"
          :src="selectedImage.logoUrl"
          :alt="selectedImage.title"
        />
      </div>
    </template>
  </HomeSection>
</template>

<script setup lang="ts">
import { ASYNC_DATA_KEYS } from '~/constants'
import {
  GOVERNMENT_LOGOS,
  LPUS_LOGOS,
  PARTNERS_CHIPS, PARTNERS_TYPES
} from '~/components/PartnerSection/constants'
import type { PartnerTypes } from '~/components/PartnerSection/types'

const { statistics } = useStatisticsData()
const { data: logos } = useNuxtData(ASYNC_DATA_KEYS.logos)
const subtitle = computed(() => {
  return $featureFlag('feature_show_new_landing')
    ? props.subtitle
    : 'С этими сервисами уже всё настроено — вам нужно только включить интеграцию'
})

type Props = {
  showOnlyPartnerType?: PartnerTypes
  subtitle?: string
}

const props = defineProps<Props>()
const selectedFilterId = ref(0)

const selectedImages = computed(() => {
  const partnerFilter = props.showOnlyPartnerType ?? PARTNERS_CHIPS[selectedFilterId.value].value

  switch (partnerFilter) {
    case PARTNERS_TYPES.lpu:
      return LPUS_LOGOS
    case PARTNERS_TYPES.insurance:
      return logos.value?.insurance
    case PARTNERS_TYPES.services:
      return logos.value?.services
    case PARTNERS_TYPES.government:
      return GOVERNMENT_LOGOS
  }
})
const imageSizes = $featureFlag('feature_show_new_landing')
  ? {
    desktop: {
      width: '264px',
      height: '109px'
    },
    mobile: {
      width: '156px',
      height: '64px'
    }
  }
  : {
    desktop: {
      width: '214px',
      height: '88px'
    },
    mobile: {
      width: '156px',
      height: '69px'
    }
  }
</script>

<style lang="scss" scoped>
@use "sass:map";

.partner-section-img {
  width: v-bind('imageSizes.mobile.width');
  height: v-bind('imageSizes.mobile.height');

  @media (min-width: $desktop-breakpoint) {
    width: v-bind('imageSizes.desktop.width');
    height: v-bind('imageSizes.desktop.height');
  }
}

body .border-color-primary {
  border-color: $t-primary !important;
}

.container-indent {
  margin-bottom: 60px;

  @media #{map.get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 100px;
  }
}
</style>
