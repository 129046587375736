import type { SubmitEventPromise } from 'vuetify'
import type { VTextField } from 'vuetify/components/VTextField'
import type { Ref } from 'vue'
import { saveContactApi } from '~/components/ContactForm/api'
import { ContactFormRequest } from '~/enums'

export const useContactForm = (payload?: { isShowDialog: Ref<boolean> }) => {
  const textField = ref<VTextField | null>(null)
  const isValidForm = ref(true)
  const formLoading = ref(false)
  const privacyPolicyLink =
        useRuntimeConfig().public.mfUrl + '/static/dms/pdf/personal_data_processing_policy.pdf'
  const phoneNumber = ref('')
  const isShowSuccessScreen = ref(false)
  const isShowErrorScreen = ref(false)
  const isShowFinalScreens = ref(false)

  const showSuccessMessage = () => {
    isShowSuccessScreen.value = true
    isShowFinalScreens.value = true
  }

  const showErrorMessage = () => {
    isShowErrorScreen.value = true
    isShowFinalScreens.value = true
  }

  const handleClickSend = async (event: SubmitEventPromise) => {
    formLoading.value = true

    await event

    if (!isValidForm.value) {
      formLoading.value = false

      return
    }

    try {
      await saveContactApi.saveContact({
        mobilePhone: getOnlyNumber(phoneNumber.value),
        requestType: ContactFormRequest.ConnectMedflex
      })

      showSuccessMessage()
    } catch {
      showErrorMessage()
    } finally {
      formLoading.value = false

      if (payload?.isShowDialog) {
        payload.isShowDialog.value = false
      }

      phoneNumber.value = ''
    }
  }

  return {
    textField,
    isValidForm,
    formLoading,
    privacyPolicyLink,
    phoneNumber,
    isShowSuccessScreen,
    isShowErrorScreen,
    isShowFinalScreens,
    handleClickSend
  }
}
