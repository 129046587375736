<template>
  <v-dialog
    v-model="isShowDialog"
    width="456"
  >
    <v-card>
      <AppToolbar
        title="Заказать консультацию"
        close-to
        @click:close="isShowDialog = false"
      />
      <v-form
        v-model="isValidForm"
        validate-on="submit"
        @submit.prevent="handleClickSend"
      >
        <div class="pa-6 mb-2">
          <PhoneInput
            v-model="phoneNumber"
            class="mb-1"
            label="Номер телефона"
            placeholder="+7 9XX XXX XX XX"
            density="comfortable"
            validate-on="blur"
            :rules="[
              (value: string) => value.length === FORMATED_PHONE_LENGTH || 'Укажите номер в формате +7 9XX XXX XX XX'
            ]"
            persistent-placeholder
            @click:control="textField?.resetValidation()"
          />

          Нажимая кнопку «Жду звонка», вы&nbsp;даёте согласие на&nbsp;<nuxt-link
            target="_blank"
            class="text-decoration-none text-primary"
            :href="privacyPolicyLink"
            external
          >обработку персональных данных</nuxt-link>
        </div>
        <div class="d-flex pb-6 px-6">
          <v-spacer />
          <v-btn
            text="Отменить"
            color="bg-gray-0"
            class="mr-4"
            @click="isShowDialog = false"
          />
          <v-btn
            type="submit"
            text="Жду звонка"
            :loading="formLoading"
          />
        </div>
      </v-form>
    </v-card>
  </v-dialog>
  <ContactFormFinalScreens
    v-model="isShowFinalScreens"
    :is-show-error-screen="isShowErrorScreen"
    :is-show-success-screen="isShowSuccessScreen"
  />
</template>

<script setup lang="ts">
import { useContactForm } from '~/components/ContactForm/composables/useContactForm'
import { FORMATED_PHONE_LENGTH } from '~/components/ContactForm/constants'

type Props = {
  modelValue?: boolean
}

const props = defineProps<Props>()

const isShowDialog = useVModel(props, 'modelValue')

const {
  isShowSuccessScreen,
  isShowErrorScreen,
  isShowFinalScreens,
  privacyPolicyLink,
  isValidForm,
  textField,
  handleClickSend,
  formLoading,
  phoneNumber
} = useContactForm({
  isShowDialog
})
</script>

<style lang="scss" scoped>
.contact-form-dialog-button {
  border-radius: 8px;
  font-size: 20px;
}
</style>
