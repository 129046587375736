import { YM_COUNTER_ID } from '~/constants'

const initYandexMetrika = () => {
  (function(m, e, t, r, i, k, a) {
    // @ts-ignore
    m[i] = m[i] || function() {
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      (m[i].a = m[i].a || []).push(arguments)
    }
    // @ts-ignore
    m[i].l = 1 * new Date()
    // @ts-ignore
    // eslint-disable-next-line
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

  window.ym(YM_COUNTER_ID, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true
  })
}

export default defineNuxtPlugin(() => {
  initYandexMetrika()
})
