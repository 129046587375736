const initMailRuMetrika = () => {
  // @ts-ignore
  const _tmr = window._tmr || (window._tmr = [])
  _tmr.push({
    id: '3534697',
    type: 'pageView',
    start: (new Date()).getTime()
  });
  (function(d, w, id) {
    if (d.getElementById(id)) {
      return
    }

    const ts = d.createElement('script')
    ts.type = 'text/javascript'
    ts.async = true
    ts.id = id
    ts.src = 'https://top-fwz1.mail.ru/js/code.js'
    const f = function() {
      const s = d.getElementsByTagName('script')[0]
      // @ts-ignore
      s.parentNode.insertBefore(ts, s)
    }

    // @ts-ignore
    if (w.opera == '[object Opera]') {
      d.addEventListener('DOMContentLoaded', f, false)
    } else {
      f()
    }
  })(document, window, 'tmr-code')
}

export default defineNuxtPlugin(() => {
  initMailRuMetrika()
})
