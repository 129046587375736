<template>
  <MainPage v-if="$featureFlag('feature_show_new_landing')" />
  <OldMainPage v-else />
</template>

<script setup lang="ts">
import { ASYNC_DATA_KEYS } from '~/constants'
import { getMisStatistics } from '~/api/misStatistics'
import MainPage from '~/features/MainPage/ui/MainPage.vue'
import OldMainPage from '~/features/MainPage/ui/OldMainPage.vue'

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://medflex.ru'
    }
  ]
})

useAsyncData(ASYNC_DATA_KEYS.misList, () => getMisStatistics(), {
  default() {
    return []
  }
})

const { statistics } = useStatisticsData()

useSeoMeta({
  description: `${statistics.value.lpusCount} - столько частных клиник и стоматологий используют сервис МедФлекс для безопасного обмена данными с онлайн-сервисами и страховыми компаниями, а также для организации онлайн-записи пациентов`
})
</script>
