<template>
  <HomeSection>
    <template
      v-if="$featureFlag('feature_show_new_landing')"
      #title
    >
      <span class="d-md-none">Интегрированы {{ data?.length }} МИС</span>
      <span class="d-none d-md-inline">{{ data?.length }} МИС уже интегрированы с МедФлекс</span>
    </template>

    <div
      :class="{
        'd-none d-md-block': $featureFlag('feature_show_new_landing')
      }"
    >
      <MisListTable
        heading-tag="h2"
        :limit="5"
        :hide-heading="$featureFlag('feature_show_new_landing')"
      />
    </div>

    <div
      v-if="$featureFlag('feature_show_new_landing')"
      class="d-md-none d-flex flex-column gr-2"
    >
      <v-sheet
        v-for="(item, index) in data?.slice(0, 5)"
        :key="index"
        height="54"
        class="pa-4 rounded-lg d-flex justify-space-between align-center bg-ui-kit-bg-secondary"
      >
        <NuxtLink
          v-if="item.isMedlock"
          target="_blank"
          class="text-primary text-decoration-none text-subtitle-1"
          :href="useRuntimeConfig().public.mlUrl"
        >
          {{ item.name }}
        </NuxtLink>
        <span
          v-else
          class="text-subtitle-1"
          v-text="item.name"
        />
        <span
          class="text-body-1 text-ui-kit-text-secondary"
          v-text="item.lpusCount"
        />
      </v-sheet>
    </div>

    <div
      v-if="data?.length"
      class="mt-6 text-center"
    >
      <v-btn
        class="bg-ui-kit-bg-secondary text-primary"
        :to="{ name: 'mis' }"
      >
        Посмотреть все {{ data.length }} МИС
      </v-btn>
    </div>
  </HomeSection>
</template>

<script setup lang="ts">
import { ASYNC_DATA_KEYS } from '~/constants'
import type { MisStatisticItemDto } from '~/api/misStatistics'

const { data } = useNuxtData<MisStatisticItemDto[]>(ASYNC_DATA_KEYS.misList)
</script>
