<template>
  <v-bottom-sheet v-model="isShowDialog">
    <v-card>
      <div class="pa-4 text-h6">
        Заказать консультацию
      </div>
      <v-divider />
      <v-form
        v-model="isValidForm"
        validate-on="submit"
        @submit.prevent="handleClickSend"
      >
        <div class="pa-6 mb-2">
          <PhoneInput
            v-model="phoneNumber"
            label="Номер телефона"
            placeholder="+7 9XX XXX XX XX"
            density="comfortable"
            validate-on="blur"
            :rules="[
              (value: string) => value.length === FORMATED_PHONE_LENGTH || 'Укажите номер в формате +7 9XX XXX XX XX'
            ]"
            active
            persistent-placeholder
            @click:control="textField?.resetValidation()"
          />

          <v-btn
            min-width="100%"
            type="submit"
            text="Жду звонка"
            class="mt-1 mb-6"
            :loading="formLoading"
          />

          <div class="text-center text-caption text-ui-kit-text-secondary">
            Нажимая кнопку «Жду звонка», вы&nbsp;даёте согласие на&nbsp;<nuxt-link
              target="_blank"
              class="text-decoration-none text-primary"
              :href="privacyPolicyLink"
              external
            >обработку&nbsp;персональных&nbsp;данных</nuxt-link>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-bottom-sheet>
  <ContactFormFinalScreens
    v-model="isShowFinalScreens"
    :is-show-error-screen="isShowErrorScreen"
    :is-show-success-screen="isShowSuccessScreen"
  />
</template>

<script setup lang="ts">
import { FORMATED_PHONE_LENGTH } from '~/components/ContactForm/constants'
import { useContactForm } from '~/components/ContactForm/composables/useContactForm'

type Props = {
  modelValue?: boolean
}

const props = defineProps<Props>()

const isShowDialog = useVModel(props, 'modelValue')

const {
  isValidForm,
  formLoading,
  handleClickSend,
  privacyPolicyLink,
  phoneNumber,
  textField,
  isShowSuccessScreen,
  isShowErrorScreen,
  isShowFinalScreens
} = useContactForm({
  isShowDialog
})
</script>
