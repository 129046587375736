<template>
  <v-text-field
    ref="textField"
    v-model="localValue"
    v-mask="phoneMask"
    v-bind="{ ...customProps, ...$attrs }"
    type="tel"
    inputmode="tel"
    :label="label"
    :error-messages="errorMessage"
    @focus="handleFocus"
    @blur="handleBlur"
    @click:control="$featureFlag('feature_show_new_landing') ? textField?.resetValidation() : () => {}"
  >
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { VTextField } from 'vuetify/components/VTextField'
import type { MaskOptions } from '~/plugins/mask/types'
import mask from '~/plugins/mask/functions/directive'

/** Компонент для ввода номера телефона со стандартной валидацией (+7##########) */
export default defineComponent({
  name: 'PhoneInput',
  directives: {
    mask
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Номер телефона'
    },
    customProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({})
    },
    errorMessage: {
      type: [Array, String] as PropType<string[] | string>,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const textField = ref<VTextField & { isFocused: boolean } | null>(null)

    const phonePrefix = $featureFlag('feature_show_new_landing') ? '+7 9' : '+7 '
    const phoneMask: MaskOptions = {
      mask: '+7 &## ###-##-##',
      tokens: {
        '&': { pattern: /9/ },
        '#': { pattern: /\d/ }
      }
    }

    const localValue = computed({
      get() {
        return props.modelValue
      },
      set(value: string) {
        emit('update:modelValue', value)
      }
    })

    watch(localValue, (val) => {
      if (!val?.startsWith(phonePrefix) && textField.value?.isFocused) {
        nextTick(() => {
          localValue.value = phonePrefix
        })
      }
    })

    function handleFocus() {
      if (!localValue.value?.startsWith(phonePrefix) || localValue.value?.length <= phonePrefix.length) {
        localValue.value = phonePrefix
      }
    }

    function handleBlur() {
      if (localValue.value?.length <= phonePrefix.length) {
        localValue.value = ''
      }
    }

    return {
      textField,
      phoneMask,
      localValue,
      handleFocus,
      handleBlur
    }
  }
})
</script>
