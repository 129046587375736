import type { Ref } from 'vue'
import type { StatisticsDto } from '~/api'
import { ASYNC_DATA_KEYS } from '~/constants'

export const useStatisticsData = () => {
  const { data } = useNuxtData<StatisticsDto>(ASYNC_DATA_KEYS.statics)

  if (!data.value) {
    throw createError({
      statusCode: 500,
      message: 'useStatisticsData: данные не были получены'
    })
  }

  return {
    statistics: data as Ref<StatisticsDto>
  }
}
