type LogoItem = {
  title: string
  logoUrl: string
}

type LogoPartnersDto = {
  insurance: LogoItem[]
  service: LogoItem[]
}
export const partnerSectionApi = {
  getLogos() {
    return $apiFetch<LogoPartnersDto>('/logos/')
  }
}
