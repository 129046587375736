<template>
  <HomeSection title="Для клиник">
    <template #subtitle>
      Передавайте через МедФлекс расписание врачей и цены сервисам<br class="d-none d-md-inline" />
      для привлечения пациентов. Мы всё настроим и обеспечим безопасность.
    </template>
    <div
      class="gr-8 gr-md-14 gc-6"
      :class="['d-flex flex-wrap justify-center']"
    >
      <v-sheet
        v-for="feature in clinicFeatures"
        :key="feature.title"
        min-width="288"
        max-width="360"
        class="clinic-feature"
      >
        <v-sheet
          class="px-2 px-md-4"
          color="ui-kit-bg-secondary"
          :class="['rounded-4']"
        >
          <v-img
            class="clinic-feature__img"
            :src="`${feature.image}.png`"
            :srcset="`${feature.image}.png 1x, ${feature.image}_3x.png 3x`"
            :alt="feature.title"
          />
        </v-sheet>
        <h3 :class="['mt-4 text-h6']">
          {{ feature.title }}
          <span
            v-if="feature.tag"
            class="clinic-feature__tag text-caption"
          >
            {{ feature.tag }}
          </span>
        </h3>
        <p
          class="mt-2 text-body-1 text-ui-kit-text-secondary"
          v-html="feature.text"
        />
      </v-sheet>
    </div>
  </HomeSection>
</template>

<script setup lang="ts">
const clinicFeatures: Array<{
  title: string
  text: string
  image: string
  tag?: string
}> = [
  {
    title: 'Включайте интеграции по клику',
    text: 'Быстро передадим расписание, цены&nbsp;и&nbsp;данные о врачах любым вашим партнёрам: порталу ПроДокторов, страховым и онлайн-сервисам записи пациентов.',
    image: 'images/features/clinicFeatures/click_integration'
  },
  {
    title: 'Используйте все каналы для привлечения пациентов',
    text: 'К вам можно будет записаться онлайн через агрегаторы, мессенджеры и соцсети. Записи попадут сразу в МИС.',
    image: 'images/features/clinicFeatures/channels_of_attraction'
  },
  {
    title: 'Снимите нагрузку с вашей МИС',
    text: 'Мы обеспечим быструю передачу данных и снизим количество запросов к МИС.',
    image: 'images/features/clinicFeatures/load_reduction_mis'
  },
  {
    title: 'Вы сами решаете, с какими сервисами сотрудничать',
    text: 'Можно в любой момент отключить интеграцию в личном кабинете.',
    image: 'images/features/clinicFeatures/choice_of_services'
  },
  {
    title: 'Защитите ваши данные',
    text: 'МедФлекс отвечает требованиям ФЗ «О&nbsp;персональных данных» и имеет аттестат соответствия требованиям защиты информации.',
    image: 'images/features/clinicFeatures/protect_data'
  },
  {
    title: 'Исключите пациентов, которые постоянно срывают записи',
    text: 'Добавьте пациентов в чёрный список, чтобы запретить им запись через онлайн-сервисы.',
    image: 'images/features/clinicFeatures/patient_lockout'
  }
]
</script>

<style scoped lang="scss">
.clinic-feature {
  &__img {
    height: 152px;

    @media (min-width: 400px) {
      height: 184px;
    }
  }

  &__tag {
    display: inline-block;
    padding: 2px 7px;
    border-radius: 10px;
    vertical-align: middle;

    background-color: $t-ui-kit-bg-primary;
    color: $t-ui-kit-text-info;
  }
}
</style>
