import type { ContactFormRequest } from '~/enums'

type SaveContactPayload = {
  requestType: ContactFormRequest
  mobilePhone: string
}

export type SaveContactErrorDto = {
  requestType?: string[]
  mobilePhone?: string[]
  detail?: string
}

export const saveContactApi = {
  saveContact(payload: SaveContactPayload) {
    return $apiFetch<SaveContactErrorDto>('/save_contact/', {
      body: payload,
      method: 'POST'
    })
  }
}
