import type { PartnerChip } from '~/components/PartnerSection/types'

export const PARTNERS_TYPES = {
  lpu: 'lpu',
  insurance: 'insurance',
  services: 'services',
  government: 'government'
} as const

export const PARTNERS_CHIPS: PartnerChip[] = [
  {
    title: 'Клиники',
    value: PARTNERS_TYPES.lpu,
    active: true
  },
  {
    title: 'Страховые',
    value: PARTNERS_TYPES.insurance,
    active: false
  },
  {
    title: 'Сервисы',
    value: PARTNERS_TYPES.services,
    active: false
  },
  {
    title: 'Государство',
    value: PARTNERS_TYPES.government,
    active: false
  }
]

export const GOVERNMENT_LOGOS = [
  {
    logoUrl: '/images/governmentLogo/minzdrav.svg',
    title: 'Министерство Здравоохранения Российской Федерации'
  },
  {
    logoUrl: '/images/governmentLogo/egisz.svg',
    title: 'ЕГИСЗ'
  }
]

export const LPUS_LOGOS = [
  {
    logoUrl: '/images/lpuLogo/invitro.svg',
    title: 'Invitro'
  },
  {
    logoUrl: '/images/lpuLogo/gemotest.svg',
    title: 'Гемотест'
  },
  {
    logoUrl: '/images/lpuLogo/helix.svg',
    title: 'Helix'
  },
  {
    logoUrl: '/images/lpuLogo/matiditya.svg',
    title: 'Мать и дитя'
  },
  {
    logoUrl: '/images/lpuLogo/alfacenterzdorovya.svg',
    title: 'Альфа центр здоровья'
  },
  {
    logoUrl: '/images/lpuLogo/mrtexpert.svg',
    title: 'МРТ эксперт'
  },
  {
    logoUrl: '/images/lpuLogo/clinicafomina.svg',
    title: 'Клиника Фомина'
  },
  {
    logoUrl: '/images/lpuLogo/ekaterininskaya.svg',
    title: 'Клиника Екатерининская'
  }
]
