<template>
  <form
    action="/landing/api/prg-link"
    method="post"
    rel="noindex nofollow"
    class="d-inline-block"
  >
    <input
      type="hidden"
      :name="headerName"
      :value="csrf"
    />

    <input
      type="hidden"
      name="url"
      :value="url"
    />
    <button
      type="submit"
      :style="{width: widthButton}"
      :class="textClass"
    >
      <v-icon
        v-if="prependIcon"
        color="ui-kit-text-info"
        class="mx-4"
        size="24"
        :icon="prependIcon"
      />
      {{ text }}
    </button>
  </form>
</template>

<script setup lang="ts">
type Props = {
  text: string
  url: string
  textClass?: string
  widthButton?: string
  prependIcon?: string
}

withDefaults(defineProps<Props>(), {
  textClass: '',
  widthButton: '100%',
  prependIcon: ''
})

const { csrf, headerName } = useCsrf()
</script>
