import type maskitFunc from '~/plugins/mask/functions/maskit'
import type { MaskTokens } from '~/plugins/mask/types'

export default function dynamicMask(
  maskit: typeof maskitFunc,
  masks: Array<string>,
  tokens: MaskTokens
) {
  masks = masks.sort((a, b) => a.length - b.length)

  return function(value: string, mask: Array<string> | string, masked = true) {
    let i = 0

    while (i < masks.length) {
      const currentMask = masks[i]
      i++
      const nextMask = masks[i]

      if (!(nextMask && maskit(value, nextMask, true, tokens).length > currentMask.length)) {
        return maskit(value, currentMask, masked, tokens)
      }
    }

    return '' // empty masks
  }
}
