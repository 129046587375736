import { getFlags } from '~/api/featureFlag'

export default defineNuxtRouteMiddleware(async (to, from) => {
  try {
    if (import.meta.client && to.name === from.name) {
      return
    }

    const nuxtApp = useNuxtApp()

    // https://nuxt.com/docs/guide/directory-structure/middleware#when-middleware-runs
    if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
      return
    }

    _useFlags().flags = await getFlags()
  } catch { }
})
