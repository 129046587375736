<template>
  <section>
    <v-container>
      <div
        class="mb-6"
        :class="$featureFlag('feature_show_new_landing') ? '' : 'mb-md-10'"
      >
        <h2
          :class="{
            'text-center text-h4': !$featureFlag('feature_show_new_landing'),
            'text-center text-h5 text-md-h4': $featureFlag('feature_show_new_landing')
          }"
        >
          <slot name="title">{{ title }}</slot>
        </h2>
        <p
          :class="$featureFlag('feature_show_new_landing')
            ? 'text-md-landing-h6 text-center mt-4 text-ui-kit-text-secondary'
            : 'text-body-2 mt-md-4 text-md-body-1 text-center'"
        >
          <slot name="subtitle">{{ subtitle }}</slot>
        </p>
      </div>
      <slot />
    </v-container>
  </section>
</template>

<script lang="ts">
export default defineComponent({
  name: 'HomeSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
})
</script>
