import payload_plugin_qLmFnukI99 from "/builds/mis/medflex_landing/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/mis/medflex_landing/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/mis/medflex_landing/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/mis/medflex_landing/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/mis/medflex_landing/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/mis/medflex_landing/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2TTjvwQPRx from "/builds/mis/medflex_landing/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/builds/mis/medflex_landing/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import index_0OSYqJ9w68 from "/builds/mis/medflex_landing/modules/ui-kit/runtime/plugin/index.ts";
import index_FI80Z3fnmz from "/builds/mis/medflex_landing/plugins/mask/index.ts";
import hash_intersect_directive_euZimn94Rm from "/builds/mis/medflex_landing/plugins/hash-intersect-directive.ts";
import mail_ru_metrika_client_OZHoGrvQqX from "/builds/mis/medflex_landing/plugins/mail-ru-metrika.client.ts";
import yandex_metrika_client_MiNHrQhx1f from "/builds/mis/medflex_landing/plugins/yandex-metrika.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_2TTjvwQPRx,
  plugin_ghbUAjaD3n,
  index_0OSYqJ9w68,
  index_FI80Z3fnmz,
  hash_intersect_directive_euZimn94Rm,
  mail_ru_metrika_client_OZHoGrvQqX,
  yandex_metrika_client_MiNHrQhx1f
]