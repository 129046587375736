export * from './wordForms'
export * from './metrics'

/**
 * @deprecated feature_show_new_landing
 * Удалить при удалении флага
 * */
export const APP_HEADER_HEIGHT = 72

export const ASYNC_DATA_KEYS = {
  statics: 'statics',
  misList: 'misList',
  logos: 'logos'
}
