<template>
  <v-container
    tag="section"
    class="main-section position-relative px-2 text-center text-md-left"
  >
    <div class="main-section__content">
      <h1 class="text-landing-h5 text-md-landing-h1">
        Защищённая облачная<br class="d-none d-md-inline" />
        платформа обмена
        данными
      </h1>
      <p class="mt-4 mt-md-7 text-body-1 text-md-landing-h6">
        между клиниками, онлайн-сервисами<br class="d-none d-md-inline" />
        и страховыми компаниями
      </p>
      <div class="my-8 d-flex flex-column flex-md-row">
        <div
          v-for="metric in metrics"
          :key="metric.name"
          class="mb-4 mb-md-0 mr-md-8"
        >
          <p class="text-landing-h5">{{ metric.count }}</p>
          <p class="mt-1 text-body-1 text-ui-kit-text-secondary">{{ metric.name }}</p>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          class="flex-grow-1 flex-md-grow-0 connect-btn"
          @click="handleClickBtn"
        >
          Подключиться
        </v-btn>
      </div>
    </div>
    <v-img
      class="main-section__image d-none d-md-inline-block"
      src="/images/features/medflex_demo_logo.svg"
      alt="Защищённый обмен данными"
    />
  </v-container>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { StatisticsDto } from '~/api'
import { appointmentDeclension, clinicDeclension, integrationDeclension } from '~/constants'

export default defineComponent({
  name: 'MainSection',
  props: {
    statistics: {
      type: Object as PropType<StatisticsDto>,
      required: true
    }
  },
  emits: ['click:open-form'],
  setup(props, { emit }) {
    const metrics = [
      {
        name: pluralize(props.statistics.lpusCount, clinicDeclension, false),
        count: props.statistics.lpusCount.toLocaleString('ru') ?? 0
      },
      {
        name: pluralize(props.statistics.integrationsCount, integrationDeclension, false),
        count: props.statistics.integrationsCount.toLocaleString('ru') ?? 0
      },
      {
        name: `онлайн-${pluralize(props.statistics.directAppointmentsCount, appointmentDeclension, false)} на приём`,
        count: props.statistics.directAppointmentsCount.toLocaleString('ru') ?? 0
      }
    ]

    function handleClickBtn() {
      emit('click:open-form')
    }

    return {
      metrics,
      handleClickBtn
    }
  }
})
</script>

<style scoped lang="scss">
.main-section {
  @media (min-width: $desktop-breakpoint) {
    height: 407px;
    max-height: 407px;
  }

  &__content {
    @media (min-width: $desktop-breakpoint) {
      position: relative;
      z-index: 1;
      max-width: 66%;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 591px;
    height: 407px;

    @media (max-width: 1050px) {
      width: 591px;
      height: 307px;
    }
  }
}

.connect-btn {
  height: 40px;

  @media (min-width: $desktop-breakpoint) {
    height: 48px;
  }
}
</style>
