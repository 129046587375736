export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, user-scalable=yes, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0"},{"hid":"og:url","property":"og:url","content":"https://medflex.ru"},{"hid":"og:title","property":"og:title","content":"МедФлекс — сервис безопасного обмена медицинскими данными"},{"hid":"og:description","property":"og:description","content":"Единая защищенная платформа для передачи данных между клиниками, онлайн-сервисами и страховыми компаниями"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:image","property":"og:image","content":"/meta/medflex-og.jpg"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/images/favicons/favicon.ico"},{"rel":"icon","type":"image/svg+xml","href":"/images/favicons/favicon.svg"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/images/favicons/apple-touch-icon-180.png"}],"style":[],"script":[{"src":"https://polyfill.medrocket.ru/v3/polyfill.min.js?version=3.111.0&features=es2021%2Ces2022%2CIntersectionObserverEntry%2CIntersectionObserver%2CMutationObserver%2CResizeObserver%2CWebAnimations%2CObject.fromEntries%2CArray.prototype.at&flags=gated","defer":true}],"noscript":[{"innerHTML":"<div><img src=\"https://top-fwz1.mail.ru/counter?id=3534697;js=na\" style=\"position:absolute;left:-9999px;\" alt=\"Top.Mail.Ru\" /></div>"}],"title":"МедФлекс - защищённая облачная платформа обмена данными","htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false